import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import MasterBulletPoints from "../../../../../V2/Cards/Content/MasterBulletPoints"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const StoryblokBulletPointIconInfoCard = ({
  blok,
}: Storyblok.BlokProps<Storyblok.BulletPointIconInfoCard>) => {
  const infoBlocks = blok.iconInfoBlocks.map((infoBlock) => ({
    iconUrl: infoBlock.icon.filename,
    iconAlt: infoBlock.icon.alt,
    titleText: infoBlock.titleText,
    bodyText: infoBlock.bodyText,
  }))

  return (
    <MasterBulletPoints
      itemType="icon_info"
      backgroundColor={blok.backgroundColor}
      title={blok.headerText}
      iconInfoBlocks={infoBlocks}
      linkText={blok.ctaText}
      linkUrl={
        blok.ctaLink && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : undefined
      }
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokBulletPointIconInfoCard
