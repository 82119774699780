import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import BulletItem from "../../../elements/V2/BulletItem"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import IconInfoBlock from "../../../elements/V2/IconInfoBlock"
import ArrowIcon from "../../../elements/V2/Icons/ArrowIcon"
import Typography from "../../../elements/V2/Typography"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type MasterBulletPointsProps = {
  itemType: "bullet_items" | "icon_info"
  backgroundColor: Color
  title: string
  body?: string
  bulletItems?: React.ComponentProps<typeof BulletItem>[]
  iconInfoBlocks?: Omit<
    React.ComponentProps<typeof IconInfoBlock>,
    "size" | "textColor"
  >[]
  linkText?: string
  linkUrl?: string
}

const MasterBulletPoints = ({
  itemType,
  backgroundColor,
  title,
  body,
  bulletItems,
  iconInfoBlocks,
  linkText,
  linkUrl,
  ...props
}: MasterBulletPointsProps) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)

  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        getBackgroundColorClass(backgroundColor),
        "rounded-lg-v2 col-span-4 flex flex-col gap-24-v2 px-24-v2 py-32-v2 sm-v2:col-span-2 md-v2:col-span-3 md-v2:gap-32-v2 md-v2:px-32-v2 md-v2:py-48-v2 lg-v2:col-span-6 lg-v2:gap-40-v2 lg-v2:px-48-v2 lg-v2:py-64-v2"
      )}
      {...props}
    >
      <div className="flex flex-col gap-16-v2">
        <Typography
          font="grotesk"
          weight="book"
          size="h5"
          text={title}
          color={getAccentColor(backgroundColor)}
          element="h3"
        />
        {itemType === "bullet_items" && body && (
          <Typography
            font="grotesk"
            weight="book"
            size="body-lg"
            text={body}
            color={getAccentColor(backgroundColor)}
          />
        )}
      </div>

      {itemType === "icon_info" && iconInfoBlocks && (
        <div className="grid grid-cols-1 gap-32-v2 md-v2:grid-cols-2">
          {iconInfoBlocks.map(
            ({ iconUrl, iconAlt, titleText, bodyText }, index) => (
              <IconInfoBlock
                key={`${titleText}-${index}`}
                iconUrl={iconUrl}
                iconAlt={iconAlt}
                titleText={titleText}
                bodyText={bodyText}
                size={"small"}
                textColor={getAccentColor(backgroundColor)}
              />
            )
          )}
        </div>
      )}

      {itemType === "bullet_items" && bulletItems && (
        <div className="flex flex-col gap-16-v2">
          {bulletItems.map(({ text }, index) => (
            <BulletItem
              key={index}
              text={text}
              size="body-md"
              textColor={getAccentColor(backgroundColor)}
              iconOverrideColor={getAccentColor(backgroundColor)}
            />
          ))}
        </div>
      )}

      {linkText && linkUrl && (
        <TextButton
          style="icon"
          text={linkText}
          icon={<ArrowIcon />}
          linkUrl={linkUrl}
          color={getAccentColor(backgroundColor)}
        />
      )}
    </div>
  )
}

export default MasterBulletPoints
